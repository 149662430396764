
import { ref } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
export default {
  data() {
    return {
      radio: "1",
      radioVal: 1,
      aimQus: 0,
      questions: store.state.questions,
    };
  },
  props: ["postaimqus", "correct"],
  setup(props, any) {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";

    return { checked, age };
  },
  methods: {
    // jump() {},
    nexStep() {
      const aimQus = this.postaimqus;
      console.log("aimQus10101010", aimQus);
      if (aimQus == 0) {
        if (this.radioVal == 1) {
          this.chanti();
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 0; //0开始 0-4
          this.chanti();
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 1) {
        if (this.radioVal == 4) {
          this.aimQus = 2; //0开始 0-4
          this.chanti(this.aimQus);
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 1; //0开始 0-4
          this.chanti(this.aimQus);
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 2) {
        if (this.radioVal == 3) {
          this.aimQus = 3; //0开始 0-4
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 2; //0开始 0-4
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 3) {
        if (this.radioVal == 1) {
          this.aimQus = 4; //0开始 0-4
          this.pinggu(this.aimQus);
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 4; //0开始 0-4
          this.pinggu(this.aimQus);
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 4) {
        if (this.radioVal == 1) {
          this.aimQus = 5; //0开始 0-4
          this.fangan(this.aimQus);
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 5; //0开始 0-4
          this.fangan(this.aimQus);
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 5) {
        //sikao
        // if (this.radioVal == 1) {
        //   this.aimQus = 6; //0开始 0-4
        //   this.sikao(this.aimQus);
        //   console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        // } else {
        //   this.aimQus = 6; //0开始 0-4
        //   this.sikao(this.aimQus);
        //   console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        // }\
        // this.aimQus = 6;
        // this.$emit("onSiKaoone", [this.aimQus, this.radioVal]);
        console.log(
          this.aimQus + "11111111111111111111111111111111111111111111111"
        );
      }
      // this.$router.push({
      //   name: "about",
      //   query: {
      //     aimQus: this.aimQus,
      //   },
      // });
      console.log("第几道题:" + this.postaimqus);
      console.log("你的选择:" + this.radioVal);
      this.$emit("onChanged", [this.aimQus, this.radioVal]);
    },
    chanti() {
      console.log("第几道题:" + "888888888");
      this.$router.push({
        name: "chati",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    pinggu() {
      console.log("第几道题:" + "111111111111111");
      this.$router.push({
        name: "pinggu",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    fangan() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "fangan",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    sikao() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "sikao",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    shikaoone() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "shikaoone",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    shikaotwo() {
      console.log("第几道题:" + "55555555");
      this.$router.push({
        name: "shikaotwo",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
  },
};
