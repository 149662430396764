import { createStore } from "vuex";

export default createStore({
  state: {
    questions: [
      {
        num: "one",
        bigTitle: "你认为该患者应该进行哪些检查",
        desc: "患者自诉慢性鼻炎多年未曾确诊，可采用鼻炎常用诊断方式进行查体检查。",
        radioList: [
          {
            name: "1",
            title: "A.鼻内镜检查",
          },
          {
            name: "2",
            title: "B.皮肤试点",
          },
          {
            name: "3",
            title: "C.血液检查",
          },
          {
            name: "4",
            title: "D.鼻激发试验",
          },
          {
            name: "5",
            title: "E.以上均可",
          },
        ],
      },
      {
        num: "two",
        bigTitle:
          "你认为该患者的临床诊断为",
        radioList: [
          {
            name: "1",
            title: "A.感染性鼻炎",
          },
          {
            name: "2",
            title: "B.变应性鼻炎",
          },
          {
            name: "3",
            title: "C.脑脊液鼻漏",
          }, {
            name: "4",
            title: "D.慢性鼻窦炎",
          },

        ],
      },
      {
        num: "four",
        bigTitle:
          "变应性鼻炎典型症状包括",
          desc: "AR的典型症状为阵发性喷嚏、清水样涕、鼻痒和鼻塞；可伴有眼部症状，包括眼痒、流泪、 眼红和灼热感等，多见于花粉过敏患者。",
        radioList: [
          {
            name: "1",
            title: "A.鼻塞",
          },
          {
            name: "2",
            title:
              "B.鼻痒",
          },
          {
            name: "3",
            title: "C.清水样流涕",
          },
          {
            name: "4",
            title: "D.阵发性喷嚏",
          },
          {
            name: "5",
            title: "E.以上都是",
          },
        ],
      },
      {
        num: "three",
        desc:"下鼻甲红肿、充血、肥大、弹性差，可呈结节状或桑椹样，减充血剂的收缩效果差为药物性鼻炎典型体征，临床需注意鉴别诊断。",
        bigTitle: "以下不能作为AR诊断依据的是",
        radioList: [
          {
            name: "1",
            title: "A.鼻部症状出现2个或以上，每天症状持续或累计在1h以上，可伴有眼部症状",
          },
          {
            name: "2",
            title: "B.鼻黏膜苍白，水肿，鼻腔水样分泌物",
          },
          {
            name: "3",
            title: "C.下鼻甲红肿、充血、肥大、弹性差，可呈结节状或桑椹样，减充血剂的收缩效果差",
          },
          {
            name: "4",
            title: "D.至少1种过敏原SPT和/或血清特异性IgE阳性，或鼻激发试验阳性",
          },
        ],
      },{
        num: "four",
        desc:"AR的治疗原则为“防治结合，四位一体”，包括环境控制、药物治疗、免疫治疗和健康教育。",
        bigTitle: "过敏性鼻炎治疗以“防治结合，四位一体”为原则，其中不包括：",
        radioList: [
          {
            name: "1",
            title: "A.环境控制",
          },
          {
            name: "2",
            title: "B.药物治疗",
          },
          {
            name: "3",
            title: "C.免疫治疗",
          },
          {
            name: "4",
            title: "D.手术治疗",
          },
          {
            name: "5",
            title: "E.健康教育",
          },
        ],
      },{
        num: "five",
        desc:"AR一线治疗药物包括鼻用激素、第二代口服和鼻用抗组胺药、口服白三烯受体拮抗剂。鼻用肥大细胞膜稳定剂属于二线治疗。",
        bigTitle: "以下哪项不是AR的一线治疗药物",
        radioList: [
          {
            name: "1",
            title: "A.鼻用激素",
          },
          {
            name: "2",
            title: "B. 抗组胺药",
          },
          {
            name: "3",
            title: "C.白三烯受体拮抗剂",
          },
          {
            name: "4",
            title: "D.鼻用肥大细胞膜稳定剂",
          },
        ],
      },{
        num: "six",
        desc:"与第一代鼻用激素相比，第二代鼻用激素具有高亲脂性、与受体结合力强、抗炎活性更强、生物利用度低等特点。",
        bigTitle: "二代鼻用激素在哪些方面优于一代鼻用激素？",
        radioList: [
          {
            name: "1",
            title: "A.抗炎活性",
          },
          {
            name: "2",
            title: "B. 受体亲和力",
          },
          {
            name: "3",
            title: "C.亲脂性",
          },
          {
            name: "4",
            title: "D.生物利用度",
          },
          {
            name: "5",
            title: "E.以上都是",
          },
        ],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
