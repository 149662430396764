import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00e354cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav" }
const _hoisted_2 = { class: "aBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.navList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: _normalizeClass(item.id == $props.menu ? 'nav-item-two' : 'nav-item')
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.title), 1)
      ], 2))
    }), 128))
  ]))
}