import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ToButton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: $props.btnAim }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_van_image, {
          class: "btn",
          width: "5.64rem",
          height: "1.3333rem",
          src: require('../assets/button.png')
        }, null, 8, ["src"]),
        _createVNode(_component_van_image, {
          class: "finger",
          width: "1.28rem",
          height: "1.28rem",
          src: require('../assets/dong.gif')
        }, null, 8, ["src"])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}