import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24c960fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "box" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = { class: "pfk" }
const _hoisted_4 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_toButton = _resolveComponent("toButton")!
  const _component_medical = _resolveComponent("medical")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_image, {
        src: require('../assets/logo.png')
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_van_image, {
        width: "9.0933rem",
        height: "7.2667rem",
        src: require('../assets/qbj.png')
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_toButton, { btnAim: $data.medica }, null, 8, ["btnAim"]),
      _createVNode(_component_van_popup, {
        show: $data.show,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($data.show) = $event))
      }, {
        default: _withCtx(() => [
          ($data.flag)
            ? (_openBlock(), _createBlock(_component_medical, {
                key: 0,
                onOnChanged: $options.updateChanged,
                postaimqus: $data.postaimqus
              }, null, 8, ["onOnChanged", "postaimqus"]))
            : _createCommentVNode("", true),
          (!$data.flag)
            ? (_openBlock(), _createBlock(_component_medical, {
                key: 1,
                onOnChanged: $options.updateChanged,
                postaimqus: $data.postaimqus
              }, null, 8, ["onOnChanged", "postaimqus"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show"])
    ])
  ]))
}