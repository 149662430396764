
import { ref } from "vue";
import { useRouter } from "vue-router";
import question from "@/views/QuestionView.vue";
import correct from "@/views/CorrectView.vue";
import reason from "@/views/ReasonView.vue";
import toNav from "@/components/ToNav.vue";
import jiexiao from "@/views/JieXiaoView.vue";
import sikao from "@/views/SiKaoView.vue";
import sikaoone from "@/views/SiKaoOneView.vue";
import sikaotwo from "@/views/SiKaoTwoView.vue";
import { showDialog } from "vant";

export default {
  components: {
    question,
    correct,
    reason,
    toNav,
    jiexiao,
    sikao,
    sikaoone,
    sikaotwo,
  },
  data() {
    return {
      show: false,
      postaimqus: 0,
      title: "传递的是一个值",
      flag: "question",
      correct: "0",
    };
  },
  setup() {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";

    return { checked, age };
  },
  methods: {
    showPopup() {
      // this.show = true;
      // this.postaimqus = 5;
      // console.log(this.show + "test it!");
      this.$router.push({
        name: "last",
      });
    },
    updateCorrect(param) {
      console.log("sikao");
      this.flag = "sikao";
      // this.postaimqus = 3;
      console.log("赋值reason");
    },
    reasonCorrect(param) {
      console.log("reasonCorrect");
      this.flag = "question";
      this.postaimqus = 1;
    },
    jiexiaoChanged(param) {
      // console.log("JieXiaoChanged");
      this.flag = "question";
      this.postaimqus = 2;
    },
    onSiKao(param) {
      // console.log("JieXiaoChanged");
      this.flag = "sikao";
      // this.postaimqus = 2;
    },
    onSiKaoone(param) {
      console.log("sikaoone");
      this.flag = "sikaoone";
      // this.postaimqus = 2;
    },
    onSiKaotwo(param) {
      console.log("sikaotwo");
      this.flag = "sikaotwo";
      // this.postaimqus = 2;
    }, //onSiKao
    onSiKaowenti(param) {
      console.log("question");
      this.flag = "question";
      this.postaimqus = 6;
    },
    updateChanged(param) {
      console.log(param[0]); //获取param1的值
      console.log(param[1]); //获取param2的值
      this.aimQus = param[0];
      this.radioVal = param[1];
      switch (this.postaimqus) {
        case (this.postaimqus = 0):
          //这里是值对应的处理
          if (this.radioVal == 0) {
            // this.$refs.elDialog.stylevan-popup;
            showDialog({
              title: "提示",
              message: "您还没有做出选择哟。",
            }).then(() => {
              // on close
            });
          } else {
            if (this.radioVal == 5) {
              this.flag = "correct";
              this.correct = 1;
              console.log("第一道题答对了，显示正确内容继续");
            } else {
              console.log("第一道题答错了，显示正确内容继续");
              this.flag = "correct";
              this.correct = "0";
            }
          }

          break;
        case (this.postaimqus = 1):
          //这里是值对应的处理
          // if (this.radioVal == 4) {
          //   this.postaimqus = 2;
          //   console.log("第二道题答对了，显示正确内容继续");
          // } else {
          //   console.log("第二道题答错了，显示正确内容继续");
          // }
          this.flag = "jiexiao";
          console.log("jiexiaojiexiaojiexiao");
          break;
        case (this.postaimqus = 2):
          //这里是值对应的处理
          if (this.radioVal == 5) {
            this.flag = "correct";
            this.correct = 1;
            // this.postaimqus = 3;
            console.log("第三道题答对了，显示正确内容继续");
          } else {
            console.log("第三道题答错了，显示正确内容继续");
            this.flag = "correct";
            this.correct = "0";
            // this.postaimqus = 3;
          }
          break;
        case (this.postaimqus = 3):
          //这里是值对应的处理
          if (this.radioVal == 1) {
            // this.postaimqus = 4;
            this.flag = "correct";
            this.correct = "1";
            console.log("第四道题答对了，显示正确内容继续");
          } else {
            this.flag = "correct";
            this.correct = "0";
            console.log("第四道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 4):
          //这里是值对应的处理
          if (this.radioVal == 4) {
            this.flag = "correct";
            this.correct = "1";
            console.log("第五道题答对了，显示正确内容继续");
          } else {
            this.flag = "correct";
            this.correct = "0";
            console.log("第五道题答错了，显示正确内容继续");
          }
          break;

        case (this.postaimqus = 5):
          //这里是值对应的处理
          if (this.radioVal == 4) {
            this.flag = "correct";
            this.correct = "1";
            console.log("第六道题答对了，显示正确内容继续");
          } else {
            this.flag = "correct";
            this.correct = "0";
            console.log("第六道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 6):
          //这里是值对应的处理
          if (this.radioVal == 5) {
            this.flag = "correct";
            this.correct = "1";
            console.log("第七道题答对了，显示正确内容继续");
          } else {
            this.flag = "correct";
            this.correct = "0";
            console.log("第七道题答错了，显示正确内容继续");
          }
          break;

        default:
          //这里是没有找到对应的值处理
          break;
      }
    },
  },
};
