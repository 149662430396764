import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import { RadioGroup, Radio, Button, Popup, Image, Dialog } from "vant";

import "../node_modules/vant/lib/index.css";


// 轮播
import VueAwesomeSwiper from "vue-awesome-swiper";
import swiper from "swiper";

import "swiper/swiper-bundle.css";

// require styles

const app = createApp(App);
app.use(Radio);
app.use(RadioGroup);
app.use(Button);
app.use(router);
app.use(store);
app.use(Dialog);


// app.use(swiperSlide);

app.use(Popup);
app.use(Image);

app.use(VueAwesomeSwiper);
// app.use(VueAwesomeSwiper /* { 全局组件的默认选项 } */);
app.mount("#app");
